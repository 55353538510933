const graphqlCacheConfig = {
  typePolicies: {
    ConfigMap: {
      keyFields: false,
      merge: true,
    },
    ContestProfile: {
      keyFields: ['memberId'],
    },
    PriceReport: {
      fields: {
        cash: {
          merge: true,
        },
        credit: {
          merge: true,
        },
      },
    },
    Recall: {
      keyFields: ['recallId'],
      merge: true,
    },
    Review: {
      keyFields: ['reviewId'],
    },
    Station: {
      fields: {
        address: {
          merge: true,
        },
        prices: {
          merge: false,
        },
      },
    },
    Location: {
      keyFields: ['displayName'],
      fields: {
        stations: {
          merge: true,
        },
      },
    },
    Vehicle: {
      keyFields: ['guid'],
      merge: true,
    },
    FuelLog: {
      keyFields: ['guid'],
      merge: true,
    },
    UserProfile: {
      keyFields: ['accountId'],
      merge: true,
    },
    Query: {
      fields: {
        favoriteStationLists: {
          // eslint-disable-next-line no-unused-vars
          merge(existing = [], incoming = []) {
            return incoming;
          },
        },
      },
    },
  },
};

export default graphqlCacheConfig;
