import { loadableReady } from '@loadable/component';
import Router from '../common/components/Router';
import entryPoint from './renderEntryPoint';
import hot from './hot-loader';

if (window) {
  // https://webpack.js.org/guides/public-path/#on-the-fly
  // eslint-disable-next-line babel/camelcase, no-undef
  __webpack_public_path__ = window.PublicPath;
}

const render = () => entryPoint({
  router: hot(Router),
});

loadableReady(() => {
  render();
});
