import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { scrollToTop } from '../../../lib/utils';
import useProfile from '../../../lib/hooks/useProfile';
import useConfig from '../../../lib/hooks/useConfig';

export default function PrivateRoute({
  children,
  component: Component,
  redirectTo,
  render,
  ...rest
}) {
  const { consumerHost, identityHost } = useConfig();
  const { isLoggedIn, accountId } = useProfile();

  const renderIfAuth = useCallback((routerProps) => {
    if (!isLoggedIn || !accountId) {
      const pathname = redirectTo || `//${identityHost}/login?return_url=//${consumerHost}${routerProps.location.pathname}`;

      return (
        <Redirect
          to={{
            pathname,
            state: { from: routerProps.location },
          }}
        />
      );
    }

    scrollToTop();

    if (children) {
      return children;
    }

    if (Component) {
      return (
        <Component {...routerProps} />
      );
    }

    if (render) {
      return render(routerProps);
    }

    return undefined;
  }, [children, consumerHost, identityHost, isLoggedIn, accountId, redirectTo, render, Component]);

  return (
    <Route {...rest} render={renderIfAuth} />
  );
}

PrivateRoute.propTypes = {
  children: PropTypes.node,
  component: PropTypes.elementType,
  redirectTo: PropTypes.string,
  render: PropTypes.func,
};

PrivateRoute.defaultProps = {
  children: undefined,
  component: undefined,
  redirectTo: undefined,
  render: undefined,
};
