export default {
  ADDRESS_CONFIRMED: 'Address_Confirmed',
  ADDRESS_EDIT_CLICK: 'Address_Edit_Click',
  ALERT_BANNER_CLICK: 'Alert_Banner_Click',
  ALERT_BANNER_CLOSE: 'Alert_Banner_Close',
  HOW_TO_EARN_POINTS_OPENED: 'How_To_Earn_Points_Open',
  MAIN_MENU_ITEM_CLICK: 'MAIN_MENU_ITEM_CLICK',
  FOOTER_ITEM_CLICK: 'Footer_Item_Click',
  SEARCH_MAP_CLICK: 'SearchList_Map',
  STATION_IMPRESSION: 'Station_Impression',
  STATION_SEARCH: 'SearchList_Search',
  SUBMIT_ENTRIES_CLICK: 'Submit_Entries_Button_Click',
  REVIEW_OPENED: 'Review_Opened',
  REVIEW_SUBMITTED: 'Review_Submitted',
  PAY_CTA_CLICKED: 'Pay_CTA_Clicked',
  PAY_PRICE_CLICKED: 'Pay_Price_Clicked',
  PAY_DETAILS_CLICKED: 'Pay_Details_Clicked',
  PAY_PRICE_MODAL_TERTIARY_CTA_CLICKED: 'Pay_Price_Modal_Tertiary_CTA_Clicked',
  PAY_MIGRATE_ACTIVATE_CARD_CLICKED: 'Pay_Migrate_Activate_Card_Clicked',
  PAY_CARD_PAYMENT_OPTIONS_CLICKED: 'Pay_Card_Payment_Options_Clicked',
  PAY_CARD_PAYNOW_CLICKED: 'Pay_Card_PayNow_Clicked',
  PAY_CARD_RESOLVENOW_CLICKED: 'Pay_Card_ResolveNow_Clicked',
  SAVINGS_LINK_CLICK: 'Savings_Link_Click',
  APP_STORE_LINK_CLICK: 'AppStore_Link_Clicked',
  SEARCH_SUBMIT: 'Search',
  HOME_SEARCH_SUBMIT: 'HeroScreen_Search',
  GASBUDDY_APP_LEGACY_AD_CLICKED: 'GasBuddy_App_Legacy_Ad_Clicked',
  GASBUDDY_CARD_LEGACY_AD_CLICKED: 'GasBuddy_Card_Legacy_Ad_Clicked',
  GASBUDDY_CARD_BANNER_CLICKED: 'GasBuddy_Card_Banner_Clicked',
  GASBACK_LANDING_OPENED: 'Pay_Web_GasBack_Landing',
  GASBACK_LEARN_MORE_CLICKED: 'GasBack_Learn_More_Clicked',
  GASBACK_HELP_CLICKED: 'GasBack_Help_Clicked',
  OPEN_RECALL_SEARCH_PAGE: 'Open_Recall_Search_Page',
  RECALL_SEARCH_SCREEN_VIEWED: 'Recall_Search_Screen_Viewed',
  RECALL_SEARCH_CHECK_NOW_CLICKED: 'Recall_Search_Check_Now_Clicked',
  RECALL_RESULTS_SCREEN_VIEWED: 'Recall_Results_Screen_Viewed',
  RECALL_RESULTS_SEARCH_AGAIN_CLICKED: 'Recall_Results_Search_Again_Clicked',
  RECALL_RESULTS_SAVE_VEHICLE_CLICKED: 'Recall_Results_Save_Vehicle_Clicked',
  RECALL_RESULTS_SEE_MORE_CLICKED: 'Recall_Results_See_More_Clicked',
  RECALL_RESULT_SHARED: 'Recall_Result_Shared',
  USER_VEHICLE_VIEWED: 'User_Vehicle_Viewed',
  CAR_PROFILE_VIEWED: 'Car_Profile_Viewed',
  ADD_VEHICLE_CLICKED: 'Add_Vehicle_Clicked',
  VEHICLE_ADDED: 'Car_Vehicle_Added',
  PRICE_REPORT_MODAL_ABANDONED: 'Price_Report_Abandoned',
  PRICE_REPORT_MODAL_OPENED: 'Price_Report',
  PRICE_REPORT_SUBMITTED: 'Price_Report_Completed',
  RECEIVED_EDIT_USERNAME_PROMPT: 'Received_Edit_Username_Prompt',
  CHANGE_USERNAME_OPENED: 'Change_Username_Opened',
  USERNAME_UPDATED: 'Username_Updated',
  BADGE_AD_CONTENT_CLICKED: 'Badge_Ad_Modal_Clicked',
  BADGE_AD_OPENED: 'Badge_Ad_Modal_Opened',
  BADGE_AD_IMPRESSIONS: 'List_Ad_Impressions',
  PREMIUM_LANDING_COMPARISON_VIEWED: 'Pay_Premium_Landing_Comparison',
  PREMIUM_LANDING_PAGE: 'Pay_Premium_Landing',
  PLUS_LANDING_PAGE: 'Pay_Plus_Landing',
  DYNAMIC_BETA_ENROLL_FAILED: 'Deal_Alert_Beta_Enroll_Error',
  REVIEW_FLAG_CLICKED: 'Review_Flag_Clicked',
  REVIEW_FLAGGED: 'Review_Flagged',
  REVIEW_AGREED: 'Review_Agreed',
  REVIEW_UNAGREED: 'Review_Unagreed',
  LANDING_CTA_CLICKED: 'Pay_Landing_Start_Clicked',
  CLOSE_MOBILE_DOWNLOAD_AD: 'Close_Mobile_Download_Ad',
  GOOGLE_ONETAP_IMPRESSION: 'Google_OneTap_Impression',
  GOOGLE_ONETAP_CLICKED: 'Google_OneTap_Clicked',
  LOGGED_IN: 'IAM_User_Successfully_Logged_In', // Same as identity-web
  REGISTERED: 'IAM_User_Successfully_Registered', // Same as identity-web
  PAY_UPGRADE_CTA_CLICKED: 'Pay_Upgrade_CTA_Clicked',
  TEXT_ME_THE_APP_CLICKED: 'TextMeTheApp_Clicked', // Changed space to _ with 8.18.5 release
  MANAGED_CREDIT_CARD_ACCOUNT_CLICKED: 'Savings_Manage_Credit_Card_Account_Clicked',
  TOGGLED_CASH_PRICES_ON: 'Toggled_Cash_Prices_On',
  DEAL_ALERT_ACTIVATED: 'Deal_Alert_Activated',
  DEAL_ALERT_CANCEL_ACTIVATION: 'Deal_Alert_Cancel_Activation',
  DEAL_ALERT_INFO_EXPANDED: 'Deal_Alert_Info_Expanded',
  DEAL_ALERT_RECEIPT_UPLOADED: 'Deal_Alert_Receipt_Uploaded',
  APP_BANNER_CLOSE_CLICKED: 'App_Banner_Close_Clicked',
  APP_BANNER_INSTALL_CLICKED: 'App_Banner_Install_Clicked',
  TCC_ROUND_TRIP_CLICKED: 'TCC_Round_Trip_Clicked',
  TCC_ONE_WAY_CLICKED: 'TCC_One_Way_Clicked',
  TCC_NON_METRIC_CLICKED: 'TCC_Non_Metric_Clicked',
  TCC_METRIC_CLICKED: 'TCC_Metric_Clicked',
  TCC_FILLED_ORIGIN_BLURRED: 'TCC_Filled_Origin_Blurred',
  TCC_FILLED_DESTINATION_BLURRED: 'TCC_Filled_Destination_Blurred',
  TCC_WAYPOINT_REMOVED: 'TCC_Waypoint_Removed',
  TCC_SWAP_ORIGIN_CLICKED: 'TCC_Swap_Origin_Clicked',
  TCC_ADD_DESTINATION_CLICKED: 'TCC_Add_Destination_Clicked',
  TCC_MY_VEHICLE_CLICKED: 'TCC_My_Vehicle_Clicked',
  TCC_NEW_VEHICLE_CLICKED: 'TCC_New_Vehicle_Clicked',
  TCC_MY_VEHICLE_CHANGED: 'TCC_My_Vehicle_Changed',
  TCC_VEHICLE_FORM_YEAR_CHANGED: 'TCC_Vehicle_Form_Year_Changed',
  TCC_VEHICLE_FORM_MAKE_CHANGED: 'TCC_Vehicle_Form_Make_Changed',
  TCC_VEHICLE_FORM_MODEL_CHANGED: 'TCC_Vehicle_Form_Model_Changed',
  TCC_VEHICLE_FORM_TRIM_CHANGED: 'TCC_Vehicle_Form_Trim_Changed',
  TCC_FUEL_CHANGED: 'TCC_Fuel_Changed',
  TCC_CALCULATE_TRIP_CLICKED: 'TCC_Calculate_Trip_Clicked',
  TCC_TRIP_CALCULATION_COMPLETED: 'TCC_Trip_Calculation_Completed',
  BRAND_PAGE_LINK_CLICKED: 'BrandPage_Link_Clicked',
};
