/**
 * Sometimes, we need an uncached URL so always let client re-request the resource.
 *
 * One of the scenarios is editing vehicle profile photo:
 * The Core-Service always give the same URL for the same vehicle even if vehicle image gets updated;
 * without the uncached url, after updating a vehicle's image, when the VehicleProfileCard changed from edit mode
 * to view mode, the image will still be the old one; also, in the VehicleSummaryCard of My Vehicles
 * page, the vehicle's image is the old one and won't get refreshed.
 * Considering there won't be too many vehicles for a user, it should not be a big concern to always
 * request the vehicle's image.
 */
export function generateUncachedUrl(originalUrl) {
  return originalUrl ? `${originalUrl}?${new Date().getTime()}` : originalUrl;
}

export function encodeAmpersand(url) {
  return url ? url.replace(/&/g, '%26') : '';
}

export function decodeAmpersand(url) {
  return url ? url.replace(/%26/g, '&') : '';
}

// The route paths for which we should NOT append a return_url when we redirect to IDENTITY_WEB
export const BLACKLISTED_ROUTES = [
  '/',
  '/login',
  '/logout',
  '/signup',
  '/password/change',
];

export function createIdentityRedirect(opts = { skipRedirect: false }) {
  return function redirectToIdentityWeb(req, res, next) {
    // The goal of this middleware function is to create a session by making user login or signup
    // If redirect requested to be skipped and a user session is in place already, then there is no need to redirect user to create a session.
    if (!!opts.skipRedirect && req.user) {
      return next();
    }

    const identityHost = req.gb.config.get('IDENTITY_HOST');
    const consumerWebHost = req.gb.config.get('CONSUMER_HOST');
    const { headers, protocol, path, query, originalUrl } = req;
    const { referer } = headers;

    const source = !!referer && referer.replace(`${protocol}://${consumerWebHost}`, '');
    const hasBlacklistedOriginalUrl = !!originalUrl && BLACKLISTED_ROUTES.includes(originalUrl.toLowerCase());
    const isNotBlacklistedRouteOrReferrer = !hasBlacklistedOriginalUrl
      || (!!referer && !BLACKLISTED_ROUTES.includes(source));

    let queryString = '';

    // BLACKLISTED_ROUTES are meant to redirect users to identity web login without appending a return_url
    // The reasoning here is identity-web would return user to consumer-web's home page by default after success
    // For any other redirects for account pages such as My Vehicle Profile page,
    // we would want to append the return_url so upon successful login/signup user would return to that return_url
    if (isNotBlacklistedRouteOrReferrer) {
      const fullOriginalUrl = `${protocol}://${consumerWebHost}${originalUrl}`;

      // fullOriginalUrl takes secondary precedence.
      // This is to account for a user clicking a login-protected page link from another project.
      // (ex: The Fuel Logbook link in the navbar)
      // However, if fullOriginalUrl is a blacklisted route, then use the referer.
      const defaultUrl = hasBlacklistedOriginalUrl ? referer : fullOriginalUrl;
      const returnUrl = query.return_url || defaultUrl;
      queryString = `?return_url=${encodeAmpersand(returnUrl)}`;
    }

    const url = `${protocol}://${identityHost}${opts.path || path}${queryString}`;
    return res.redirect(opts.status || 302, url);
  };
}
