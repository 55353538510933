/* eslint-disable */
const ReactCriteo = {

  initialize(key) {
    const script = document.createElement('script');
    script.src = '//static.criteo.net/js/ld/ld.js';
    script.type = 'text/javascript';
    script.async = true;
    const firstScript = document.getElementsByTagName('script')[0];
    firstScript.parentNode.insertBefore(script, firstScript);
    ReactCriteo.event(
      { event: 'setAccount', account: key },
      { event: 'setSiteType', type: ReactCriteo.getDeviceType() }
    );
  },

  getDeviceType() {
    if (typeof window === 'undefined') {
      return false;
    }

    const userAgent = window.navigator.userAgent;

    // More readable version of Criteo's definition of device Type
    if (/iPad/.test(userAgent)) {
      return 't';
    } else if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(userAgent)) {
      return 'm';
    }

    return 'd';
  },

  event(...events) {
    if (typeof window === 'undefined') {
      return false;
    }

    window.criteo_q = window.criteo_q || [];
    window.criteo_q.push(...events);
  }

};
/* eslint-enable */

export default ReactCriteo;
