/**
 * List of brands and corresponding data to show on /brands/{brandName}
 */
export default [
  { // the key that matches the URI
    brandId: 122,
    uri: 'shell',
    name: 'Shell', // the display name for the mini-hero banner
    description: 'Royal Dutch Shell is a branded-fuel provider selling fuel at 14,000 locations in the United States. It has a number of savings and loyalty programs allowing users to save up to 10¢ per gallon. Additionally, Shell offers its motorist app that allows customers to track their loyalty points balance, transactions, find gas stations and keep up to date on any promotions Shell is running.', // the long-winded description,
    imageUrl: 'https://images.gasbuddy.io/b/122.png', // https://images.gasbuddy.com/di/b/1/122.png?w=256
  },
  {
    brandId: 38,
    uri: 'costco',
    name: 'Costco',
    description: 'Costco was founded in 1976 as a membership-only warehouse club and, in 1996, it opened its first gas station as a members-only fuel station. Costco was recognised by GasBuddy as the cheapest fuel station nationwide for the 5th year in a row. Membership costs $60 per year.',
    imageUrl: 'https://images.gasbuddy.io/b/38.png',
  },
  {
    brandId: 23,
    uri: 'bp',
    name: 'BP',
    description: 'BP is a global energy brand with around 7,000 gas stations in the United States. BP has a consumer loyalty program, called BPme Rewards, that makes fueling up and saving easier and is available at participating BP and Amoco retail stations nationwide. Exclusively available in the BPme app, BPme app users can complete their fueling transaction directly through a mobile device, to avoid swiping a credit card or using the pin pad. The app also combines all fuel rewards into a single place so it’s easy to track, receive additional savings, and automatically apply rewards to purchases. BPme app users save 5-cents on every gallon, each time they fill up at participating BP and Amoco stations during the first month of use. Those who spend $100 on fuel each calendar month will continue to automatically get 5-cents off every gallon with no gallon limitations. In addition, new bonuses and in-app promotions will give consumers the opportunity to save even more on their fuel purchases. To further maximize savings, those who open a new BP Visa® Credit Card or BP Credit Card and enroll in BPme Rewards will save 30-cents per gallon on every gallon purchased at participating BP and Amoco stations during the first 30 days from their account open date.',
    imageUrl: 'https://images.gasbuddy.io/b/23.png',
  },
  {
    brandId: 92,
    uri: 'mobil',
    name: 'Mobil',
    description: 'There are over 11,000 Exxon- and Mobil-branded gas stations selling fuel in the United States. Exxon and Mobile offer multiple loyalty and savings programs. The Speedpass app allows you to locate all Exxon and Mobil stations and earn rewards. The Smart Card program allows you to save money while filling up and earn reward points through the Plenti loyalty program.',
    imageUrl: 'https://images.gasbuddy.io/b/92.png',
  },
  {
    brandId: 125,
    uri: 'speedway',
    name: 'Speedway',
    description: 'Speedway owns and operates approximately 2,730 convenience stores in the United States making them the second-largest, company-owned and operated convenience store chain in the U.S.. Speedway offers its customers rewards and savings through its Speedy Rewards program.  The rewards program is accessible via Speedy Rewards Mastercard and the Speedy Rewards Pay Card which allows you to save 5¢ per gallon of gas for the first 90 days in addition to collecting Speedy Rewards points.  The program is also accessible through the Speedway Fuel and Speedy Rewards mobile app.',
    imageUrl: 'https://images.gasbuddy.io/b/125.png',
  },
  {
    brandId: 31,
    uri: 'chevron',
    name: 'Chevron',
    description: 'Chevron-branded fuel is sold at nearly 8,000 Chevron and Texaco retail stations in the United States. Chevron’s ExtraMile convenience stores operate at over 700 franchised sites on the west coast. It offers a number of customer loyalty and savings programs.  Chevron offers three card programs that each let you earn 3¢ back per gallon every time you fill up at a Chevron or Texaco gas station among other benefits.  Additionally, customers can use the Chevron with Techron Station Finder app to locate stations and stay up to date with promotions.',
    imageUrl: 'https://images.gasbuddy.io/b/31.png',
  },
  {
    brandId: 87,
    uri: 'marathon',
    name: 'Marathon',
    description: 'Marathon brand gasoline is available through branded retail outlets in the U.S., including the District of Columbia. In addition to quality Marathon gasoline and diesel, these retail outlets offer a wide range of programs and services including the MakeItCount™ Rewards program, convenience store products, car washes, and co-branded food products.',
    imageUrl: 'https://images.gasbuddy.io/b/87.png',
  },
  {
    brandId: 48,
    uri: 'exxon',
    name: 'Exxon',
    description: 'There are over 11,000 Exxon- and Mobil-branded gas stations selling fuel in the United States. Exxon and Mobile offer multiple loyalty and savings programs. The Speedpass app allows you to locate all Exxon and Mobil stations and earn rewards. The Smart Card program allows you to save money while filling up and earn reward points through the Plenti loyalty program.',
    imageUrl: 'https://images.gasbuddy.io/b/48.png',
  },
  {
    brandId: 130,
    uri: 'sunoco',
    name: 'Sunoco',
    description: 'Sunoco’s retail business markets its brand of gas via approximately 4,900 stations in 26 states. It has over 650 APlus-branded convenience stores. Sunoco offers multiple ways to save money:  you can earn fuel discounts using the APlus Rewards Card at APlus convenience stores, save 5¢ per gallon when using the Sunoco Rewards credit card at Sunoco stations and save on gas while shopping for groceries with their Grocery Rewards program.',
    imageUrl: 'https://images.gasbuddy.io/b/130.png',
  },
  {
    brandId: 142,
    uri: 'valero',
    name: 'Valero',
    description: 'Valero is North America’s largest independent petroleum refiner and marketer as well as one of the United States’ fastest growing fuel brands with nearly 5,800 branded outlets. Valero offers several card rewards programs. The Valero Consumer Credit Card offers up to 8¢ off per gallon at Valero stations every month. It also offers a fleet credit card program that allow fleet drivers to save up to 8¢ per gallon at Valero stations.',
    imageUrl: 'https://images.gasbuddy.io/b/142.png',
  },
  {
    brandId: 20,
    uri: 'arco',
    name: 'ARCO',
    description: 'ARCO has over 1,300 stations located in the western United States selling Top Tier fuel. Customers of ARCO may use their smartphone’s mobile wallet to pay at any ARCO station.',
    imageUrl: 'https://images.gasbuddy.io/b/20.png',
  },
  {
    brandId: 118,
    uri: 'safeway',
    name: 'Safeway',
    description: 'Safeway is a large food and drug convenience store chain with over 1,300 retail locations in the United States. Safeway offers a gas rewards program to its customers allowing them to earn points toward fuel savings by purchasing groceries, gift cards and pharmacy items at Safeway locations using the Safeway Club Card. Additionally, the Safeway apps let you check for offers, shop online, order groceries to be delivered and fill prescriptions on the go.',
    imageUrl: 'https://images.gasbuddy.io/b/118.png',
  },
  {
    brandId: 61,
    uri: 'gulf',
    name: 'Gulf',
    description: 'Gulf Oil is a branded fuel distributor that sells its products at over 1,800 Gulf-branded gasoline retail centers in North America as well as more than 1,000 private-label, retail outlets operated by major chain retailers. It rewards its customers through its card programs including a Gulf Visa Card which allows you to earn points and rewards to be redeemed at Gulf stations and the GulfCard which offers PowerPoints to help you lower your expenses at Gulf stations. Gulf will also offer mobile pay through its Gulf Pay app.',
    imageUrl: 'https://images.gasbuddy.io/b/61.png',
  },
  {
    brandId: 33,
    uri: 'citgo',
    name: 'CITGO',
    description: 'CITGO is a branded gasoline distributor that sells its product at over 5,300 branded retail outlets in 29 states in the US. It offers its customers rewards through their card programs. The CITGO Rewards Card allows users to earn 5¢ on every gallon in fuel statement credits (10¢ in the first 3 months). CITGO also offers their Check Card as an ACH solution that lets users receive a 1% discount on gas at the pump and in-store purchases, as well as a CITGO Fleet card for business use. Additional benefits may be found through CITGO’s Club CITGO mobile app.',
    imageUrl: 'https://images.gasbuddy.io/b/33.png',
  },
  {
    brandId: 32,
    uri: 'circle-k',
    name: 'Circle K',
    description: 'Circle K is a convenience store chain with over 3,300 stores in the United States. It’s customers can take advantage of several rewards and loyalty programs offered by Circle K. Circle K Easy Pay allows you to save 6¢ per gallon at Circle K gas pumps as well as earn 10 points per gallon and 20 points per dollar spent on eligible products at Circle K stores to be redeemed for Circle K Cash. The Circle K app also allows users to search for special deals, find the nearest Circle K, and save money.',
    imageUrl: 'https://images.gasbuddy.io/b/32.png',
  },
  {
    brandId: 135,
    uri: 'texaco',
    name: 'Texaco',
    description: 'Chevron-branded fuel is sold at nearly 8,000 Chevron and Texaco retail stations in the United States. Chevron’s ExtraMile convenience stores operate at over 700 franchised sites on the west coast. It offers a number of customer loyalty and savings programs.  Chevron offers three card programs that each let you earn 3¢ back per gallon every time you fill up at a Chevron or Texaco gas station among other benefits.  Additionally, customers can use the Chevron with Techron Station Finder app to locate stations and stay up to date with promotions.',
    imageUrl: 'https://images.gasbuddy.io/b/135.png',
  },
  {
    brandId: 109,
    uri: 'racetrac',
    name: 'RaceTrac',
    description: 'RaceTrac is a convenience store chain operating over 600 company-owned and third-party contract-operated stores under the names RaceTrac and RaceWay in the United States. RaceTrac offers a rewards program through the RaceTrac Rewards App.  The Rewards App allows users to earn points both within the store as well as at the pump to be redeemed at RaceTrac stores. Additionally, RaceTrac has a fleet card program that allows users to earn rebates of 5¢ per gallon for the first 90 days.',
    imageUrl: 'https://images.gasbuddy.io/b/109.png',
  },
  {
    brandId: 36,
    uri: 'conoco',
    name: 'Conoco',
    description: 'Conoco is a branded fuel provider selling its products at over 2,000 locations in North America. It offers several rewards programs to its users. The Conoco Kickback Points Card lets you earn points at the pump and in the store that can be redeemed at Conoco locations. The Conoco Credit Card allows users to earn 5¢ per gallon at Conoco stations and the My Conoco App helps Conoco customers find stations, earn points and search for promotions.',
    imageUrl: 'https://images.gasbuddy.io/b/36.png',
  },
  {
    brandId: 136,
    uri: 'thorntons',
    name: 'Thorntons',
    description: 'Thorntons is an American gas and convenience store retail chain with approximately 180 stores in the United States.  Thorntons offers several types of rewards programs to its customers.  Through their Refreshing Rewards app, users can track their Refreshing Rewards and FREEquency rewards offers as well as locate the nearest station.  Through the app, customers can also take advantage of Smoker Offers.',
    imageUrl: 'https://images.gasbuddy.io/b/136.png',
  },
  {
    brandId: 101,
    uri: 'phillips-66',
    name: 'Phillips 66',
    description: 'Phillips 66 is a branded fuel supplier with over 2,300 gas stations in the United States.  Phillips 66 rewards its customers with a number of loyalty programs.  The Phillips 66 personal credit card allows users to earn a minimum of 5¢ per gallon at Phillips 66 stations.  Additionally, the Kickback Card lets users earn points at participating Phillips 66 stations.  Customers can also use the My Phillips 66 app to search for stations, find promotions, and track their points.',
    imageUrl: 'https://images.gasbuddy.io/b/101.png',
  },
  {
    brandId: 28,
    uri: 'casey-s',
    name: 'Casey\'s',
    description: 'Casey’s is a convenience store chain that sells gas and prepared food, among other items, at over 1,900 retail locations in the United States.  Casey’s customers can find great deals in a number of places through their rewards programs.  Customers may opt in to receiving emails and texts to learn about in-store deals, member-only coupons, and updates on Casey’s news as a member of the Casey’s Club.  The Casey’s Visa Card program lets members earn rewards on purchases at Casey’s stores, save money on all purchases, and earn points among other benefits.  Casey’s is also a partner in the Hy-Vee Fuel Saver program allowing customers to earn discounts and rewards at the pump through purchases at Hy-Vee stores. Additionally, the Casey’s mobile app, lets users access coupons, order online, and locate nearby Casey’s locations to name a few benefits.',
    imageUrl: 'https://images.gasbuddy.io/b/28.png',
  },
  {
    brandId: 108,
    uri: 'quiktrip',
    name: 'QuikTrip',
    description: 'QuikTrip is a convenience store chain that sells gas at over 700 retail locations across 11 states in the United States.  QuikTrip customers have the opportunity to save money by using the QuikTrip Credit Card.  This card lets its users save a minimum of 3¢ per gallon and up to 22¢ per gallon as a statement credit in the first two billing cycles (up to 200 gallons).  Additionally, users can earn points using the QuikTrip Credit Card at a rate of 1 point per $1.  The QT Mobile App lets users order food online, find coupons and deals, stay up to date on fuel prices, and locate nearby stations.',
    imageUrl: 'https://images.gasbuddy.io/b/108.png',
  },
  {
    brandId: 13,
    uri: 'esso',
    name: 'Esso',
    description: 'There are over 11,000 Exxon- and Mobil-branded gas stations selling fuel in the United States. Exxon and Mobile offer multiple loyalty and savings programs. The Speedpass app allows you to locate all Exxon and Mobil stations and earn rewards. The Smart Card program allows you to save money while filling up and earn reward points through the Plenti loyalty program.',
    imageUrl: 'https://images.gasbuddy.io/b/13.png',
  },
  {
    brandId: 16,
    uri: 'aafes',
    name: 'AAFES',
    description: 'No Description',
    imageUrl: 'https://images.gasbuddy.io/b/16.png',
  },
  {
    brandId: 14,
    uri: '7-eleven',
    name: '7-Eleven',
    description: 'No Description',
    imageUrl: 'https://images.gasbuddy.io/b/14.png',
  },
];
