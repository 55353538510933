import Cookies from 'universal-cookie';

const universalCookies = new Cookies();
const DEBUG_KEY = 'debug';

export function handleDebugValue(debug, cookies) {
  if (debug === '0') {
    cookies.remove(DEBUG_KEY);
  } else if (debug) {
    // Drop a cookie to ensure debug mode remains enabled for a day
    cookies.set(DEBUG_KEY, 1, { maxAge: 24 * 60 * 60 });
  }
}

function checkDebugMode() {
  const debug = new URLSearchParams(window.location.search).get(DEBUG_KEY) || universalCookies.get(DEBUG_KEY);

  handleDebugValue(debug, universalCookies);

  return debug;
}

export default function logEvent(eventName, attributes) {
  if (checkDebugMode()) {
    if (attributes) {
      // eslint-disable-next-line no-console
      console.group('Event Triggered', eventName);
      // eslint-disable-next-line no-console
      console.dir(attributes);
      // eslint-disable-next-line no-console
      console.groupEnd();
    } else {
      // eslint-disable-next-line no-console
      console.log('Event Triggered', eventName);
    }
  }
}
