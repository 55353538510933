import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import graphqlCacheConfig from '../../common/graphqlCache';

const csrf = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      gbcsrf: window.gbcsrf,
    },
  }));

  return forward(operation);
});

const fileUpload = createUploadLink({
  headers: {
    'Apollo-Require-Preflight': true,
  },
  uri: '/graphql',
});

const client = new ApolloClient({
  // eslint-disable-next-line no-underscore-dangle
  cache: new InMemoryCache(graphqlCacheConfig).restore(window.__APOLLO_STATE__),
  link: ApolloLink.from([
    csrf,
    fileUpload,
  ]),
});

export default client;
