import { hot } from 'react-hot-loader';

const shouldEnableHot = (router) => {
  if (process.env.NODE_ENV === 'development') {
    return hot(module)(router);
  }

  return router;
};

export default shouldEnableHot;
