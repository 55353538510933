function formatDollars(dollars) {
  if (dollars % 1) {
    return dollars.toFixed(2);
  }

  return dollars;
}

function formatCents(cents) {
  return cents < 100 ? `${cents}¢` : `$${formatDollars(cents / 100)}`;
}

export default function formatDiscount(centsOff, maxCentsOff) {
  return maxCentsOff ? `up to ${formatCents(maxCentsOff)}` : formatCents(centsOff);
}
