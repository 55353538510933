import { hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ApolloProvider } from '@apollo/client';
import client from './graphql/client';

export default function renderEntryPoint({ router: Router }) {
  const container = document.getElementById('root');
  const supportsHistory = 'pushState' in window.history;

  const render = (Component) => {
    hydrateRoot(container, (
      <ApolloProvider client={client}>
        <HelmetProvider>
          <BrowserRouter forceRefresh={!supportsHistory}>
            <Component />
          </BrowserRouter>
        </HelmetProvider>
      </ApolloProvider>
    ));
  };

  render(Router);
}
