import { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const PageContext = createContext();

export function Provider({ children, value }) {
  const { navKey: defaultNavKey, pageName: defaultPageName } = value;
  const [pageName, setPageName] = useState(defaultPageName);
  const [navKey, setNavKey] = useState(defaultNavKey);

  return (
    <PageContext.Provider value={{ pageName, setPageName, navKey, setNavKey }}>
      {children}
    </PageContext.Provider>
  );
}

Provider.propTypes = {
  children: PropTypes.node,
  value: PropTypes.shape({
    navKey: PropTypes.string,
    pageName: PropTypes.string,
  }),
};

Provider.defaultProps = {
  children: undefined,
  value: {},
};

export default PageContext;
